<template>
    <div v-if="visible" class="popup-overlay" @click.self="closePopup">
      <div class="popup-content">
        <button class="close-button" @click="closePopup">×</button>
        <slot>Due to the ongoing Canada Post strike, delivery timing of any redeemed books will be delayed. At this time, we cannot guarantee when shipments will be received until the strike is resolved.</slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      visible: {
        type: Boolean,
        required: true,
      },
    },
    emits: ['close'],
    methods: {
      closePopup() {
        this.$emit('close');
      },
    },
  };
  </script>
  
  <style>
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999999;
  }
  
  .popup-content {
    background: white;
    padding: 30px 20px;
    border-radius: 10px;
    max-width: 420px;
    width: 90%;
    text-align: center;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 2px;
    right: 5px;
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
  }
  </style>
  